exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-category-key-tsx": () => import("./../../../src/pages/{Category.key}.tsx" /* webpackChunkName: "component---src-pages-category-key-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-creation-category-creation-key-tsx": () => import("./../../../src/pages/{Creation.category}/{Creation.key}.tsx" /* webpackChunkName: "component---src-pages-creation-category-creation-key-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legals-tsx": () => import("./../../../src/pages/legals.tsx" /* webpackChunkName: "component---src-pages-legals-tsx" */),
  "component---src-pages-lexicon-tsx": () => import("./../../../src/pages/lexicon.tsx" /* webpackChunkName: "component---src-pages-lexicon-tsx" */)
}

